/* button primary */

.btn {
  /* border: 1px solid var(--color-primary); */
  display: flex;
  align-items: center;
  font-size: 600;
  font-family: var(--font-family-secondary);
  font-weight: 500;
  color: var(--color-primary);
  border-collapse: collapse;
  border: 1px solid var(--color-primary);
  height: 42px;
  line-height: 42px;
}
.btn > span {
  display: inline-block;
  text-align: center;
  text-decoration: none;
  white-space: nowrap;
  transition: background-size 0.4s ease;
  background-image: linear-gradient(var(--color-primary), var(--color-primary));
  background-repeat: no-repeat;
}

.btn > span:nth-child(1) {
  padding: 0 24px;
  height: 100%;
  color: white;
  flex: 1;

  background-position: right;
}
.btn > span:nth-child(2) {
  width: max-content;
  height: 100%;
  padding: 0 12px;
  display: flex;
  align-items: center;
  justify-content: space-between;

  background-position: left;
}

/* # button variant primary */

.btn-primary > span:nth-child(1) {
  background-size: 100% 100%;
}
.btn-primary:hover > span:nth-child(1) {
  background-size: 0 100%;
}
.btn-primary > span:nth-child(2) {
  background-size: 0 100%;
}
.btn-primary:hover > span:nth-child(2) {
  background-size: 100% 100%;
}

/* # button variant secondary */

.btn-secondary > span:nth-child(1) {
  background-size: 0 100%;
}
.btn-secondary:hover > span:nth-child(1) {
  background-size: 100% 100%;
}
.btn-secondary > span:nth-child(2) {
  background-size: 100% 100%;
}
.btn-secondary:hover > span:nth-child(2) {
  background-size: 0 100%;
}

/* # media */

@media (min-width: 1600px) {
  .btn > span:nth-child(2) {
    padding: 0px 16px;
  }
}

@media (min-width: 992px) {
  .btn {
    height: 54px;
    line-height: 54px;
  }
}

@media (min-width: 768px) {
  .btn {
    height: 48px;
    line-height: 48px;
  }
}
