/* ================ IMPORTS ================ */

/*  # tailwind imports */
@tailwind base;
@tailwind components;
@tailwind utilities;

/* font cdn/imports */
@import url('https://fonts.googleapis.com/css2?family=Open+Sans:wght@300;400;500;600;700;800;900&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Montserrat:wght@300;400;500;600;700;800;900&display=swap');

/* # bring in normalize.css styles */
@import-normalize;

/* # custom font-face */
@import '../assets/fonts/typefaces.css';

/* ================ VARIABLES ================ */

@layer base {
  :root {
    --body-bg: #000000;

    --font-family-primary: 'Montserrat', sans-serif;
    --font-family-primary-serif: 'Gotham Book', sans-serif;
    --font-family-secondary: 'Open Sans';
    --font-family-tertiary: 'GoodTimesRg-Regular';
    --font-size-primary: 16px;
    --line-height-primary: 1.2;
    --box-shadow-primary: 0px 0px 20px #0000002b;

    --color-primary: #ff005e;
    --color-primary-500: #ff005e50;
    --color-secondary: #0f0f0f;
    --color-secondary-300: #1b1b1b;
    --color-white: #ffffff;
    --color-dark: #242424;
    --color-info: #0084c4;
    --color-danger: #ed1c17;
    --color-success: #1ed760;
    --color-warning: #ffbb00;
    --color-warning-800: #ff9900;
    --color-purple: #843ab5;
    --color-gray: #666666;
    --color-dark-gray: #202020;
    --color-sky-blue: #04d9ff;
    --color-dark-green: #15202b;
  }
}

/* ================ BASE STYLING ================ */

body,
html {
  font-family: var(--font-family-primary);
  font-size: var(--font-size-primary);
  line-height: var(--line-height-primary);
  background: var(--body-bg);
  scroll-behavior: smooth;
  overflow-x: hidden;
  box-sizing: border-box;
  color: white;
}
a,
p {
  font-family: var(--font-family-primary-serif);
}
a {
  color: inherit;
}
img,
svg {
  display: inline-block;
}
p {
  line-height: 1.3;
}

/*customized scroll bar  */

html {
  scrollbar-color: var(--color-primary) transparent;
  scrollbar-width: thin;
}

html::-webkit-scrollbar,
html::-webkit-scrollbar {
  width: 2px;
}

html::-webkit-scrollbar-track,
html::-webkit-scrollbar-track {
  background: transparent;
}
html::-webkit-scrollbar-thumb,
html::-webkit-scrollbar-thumb {
  background-color: transparent;
}

/* ================  CUSTOM UTILITIES ================ */

.svg-fill-white path {
  fill: white !important;
}

.font-secondary {
  font-family: var(--font-family-secondary);
}

.svg-fill--primary:hover path {
  fill: var(--color-primary) !important;
}

@layer utilities {
  /* Chrome, Safari and Opera */
  .no-scrollbar::-webkit-scrollbar {
    display: none;
  }

  .no-scrollbar {
    -ms-overflow-style: none; /* IE and Edge */
    scrollbar-width: none; /* Firefox */
  }
}

@layer utilities {
  .gradient-from-bottom {
    background: linear-gradient(180deg, #00000000 0%, #000000be 90%, #000000 100%) 0% 0% no-repeat padding-box;
  }
  .gradient-from-bottom-50 {
    background: linear-gradient(180deg, #00000000 0%, #00000060 73%, #000000 103%) 0% 0 no-repeat padding-box;
  }
  .m-screen {
    @apply max-w-[1920px] mx-auto;
  }
}

/* ================  GLOBAL MEDIA ================ */

/* # media */
@media screen and (min-width: 1600px) {
  body,
  html {
    font-size: 20px;
  }
}
/* # media */
@media screen and (max-width: 640px) {
  body,
  html {
    font-size: 14px;
  }
}
