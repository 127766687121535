.dropdown {
  position: relative;
  display: inline-block;
}
.dropdown button > img {
  margin-left: 12px;
}
.dropdown__menu {
  position: absolute;
  top: 40px;
  right: 0;

  padding-top: 24px;

  background-color: var(--color-secondary);
  min-width: 297px;
  min-height: max-content;

  animation: fadeIn 0.2s ease-in-out;
}

.dropdown__menu li {
  display: flex;
  align-items: center;
  justify-content: space-between;

  height: 49px;
  padding: 14px 30px;
  font-weight: 500;
  font-size: 0.94rem;

  transition: all 0.3s ease-in-out;
}
.dropdown__menu li:hover {
  background-color: var(--color-primary);
}
.dropdown__menu .bg--danger:hover {
  background-color: var(--color-danger);
}
.dropdown__menu .bg--success:hover {
  background-color: var(--color-success);
}
.dropdown__menu .bg--info:hover {
  background-color: var(--color-info);
}
.dropdown__menu .bg--secondary:hover {
  background-color: var(--color-secondary);
}
.dropdown__menu .bg--purple:hover {
  background-color: var(--color-purple);
}
.dropdown__menu .bg--warning:hover {
  background-color: var(--color-warning);
}
.dropdown__menu .bg--warning-800:hover {
  background-color: var(--color-warning-800);
}

@keyframes fadeIn {
  0% {
    opacity: 0;
  }
  100% {
    opacity: 1;
  }
}
