/* # PRIMARY HEADER */

main {
  margin-top: 124px;
}

/* # Global styles for slick arrow */
/* # wrap this with a wrapper className if it has conflict with other slick sliders */

.slick-slider .slick-next {
  right: -80px;
}
.slick-slider .slick-prev {
  left: -80px;
}
.slick-next:before,
.slick-prev:before {
  content: '';
}
.secondary-slick-slider .slick-slider .slick-next {
  z-index: 1;
  right: 10%;
}
.secondary-slick-slider .slick-slider .slick-prev {
  left: 10%;
  z-index: 1;
}

/* # primary image slick slider */
.primary-slick-gallery svg {
  width: 40px;
  height: 40px;
  fill: white;
}

.primary-slick-gallery img {
  object-fit: cover;
  width: 100%;
}
/* slick arrows */
.primary-slick-gallery :is(.slick-prev:before, .slick-next:before) {
  content: '' !important;
}
.primary-slick-gallery .slick-dots {
  display: flex !important;
}
.primary-slick-gallery .slick-dots li {
  flex: 1;
}
.primary-slick-gallery .slick-dot {
  border: 1px solid var(--color-primary);
}
.primary-slick-gallery .slick-active .slick-dot {
  position: relative;
}
.primary-slick-gallery .slick-active .slick-dot:after {
  content: '';
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background: var(--color-primary-500);
}

/* # primary slick cards */
.primary-slick-cards .slick-prev:before {
  content: ''; /* hide slick prev arrow */
}

@media (max-width: 992px) {
  .primary-slick-gallery .slick-next {
    display: none !important;
  }
  .primary-slick-gallery .slick-prev {
    display: none !important;
  }
}

/* # password protected page  */

.password-protected__hero {
  background: linear-gradient(180deg, #000000 0%, #000000 0%, #00000000 10%, #000000 81%, #000000 100%) 0% 0% no-repeat
      padding-box,
    url('../assets/images/bg-main.png') 0% 0% / cover no-repeat;
}
