.hamburger .line {
  width: 32px;
  height: 4px;
  background-color: var(--color-primary);
  display: block;
  margin: 7px auto;
  -webkit-transition: all 0.3s ease-in-out;
  -o-transition: all 0.3s ease-in-out;
  transition: all 0.3s ease-in-out;
}

.hamburger:hover {
  cursor: pointer;
}

#hamburger-1.is-active .line:nth-child(2) {
  opacity: 0;
}

#hamburger-1.is-active .line:nth-child(1) {
  -webkit-transform: translateY(11.5px) rotate(45deg);
  -ms-transform: translateY(11.5px) rotate(45deg);
  -o-transform: translateY(11.5px) rotate(45deg);
  transform: translateY(11.5px) rotate(45deg);
}

#hamburger-1.is-active .line:nth-child(3) {
  -webkit-transform: translateY(-11.5px) rotate(-45deg);
  -ms-transform: translateY(-11.5px) rotate(-45deg);
  -o-transform: translateY(-11.5px) rotate(-45deg);
  transform: translateY(-11.5px) rotate(-45deg);
}
