.tabs {
  display: flex;
  flex-wrap: wrap;
  gap: 10px;
}

.tabs .tab-item {
  min-width: 110px;
  height: 34px;
  line-height: 34px;
  border: 1px solid var(--color-primary);
  display: inline-block;
  text-align: center;
  font-size: 0.87rem;
  font-family: var(--font-family-secondary);
}

.tabs .tab-item .tab-item__btn {
  width: 100%;
  padding: 0 10px;
}
.tabs .tab-item--active {
  background: var(--color-primary);
  font-weight: 600;
}

.tab-item__content {
  margin: 60px 0;
}

@media (min-width: 768px) {
  .tabs .tab-item {
    min-width: 137px;
    height: 42px;
    line-height: 42px;
  }
}
