input {
  height: 42px;
  width: 100%;
  background: #ffffff27;
  backdrop-filter: blur(3px);
  -webkit-backdrop-filter: blur(3px);
  padding: 16px 24px !important;
  color: #ffffff;
  border-radius: 2px;
  font-size: 0.8rem !important;
}

/* # media */

@media (min-width: 992px) {
  input {
    height: 54px;
  }
}

@media (min-width: 768px) {
  input {
    height: 48px;
  }
}

/* # end media */

/* # Input feedback message */

/* error message */
.message {
  text-align: left;
  animation: fade-down 0.2s ease-in-out;
}
.error-message {
  color: #ff4d4f;
}
@keyframes fade-down {
  0% {
    opacity: 0;
    transform: translateY(-10px);
  }
  100% {
    opacity: 1;
    transform: translateY(0);
  }
}
